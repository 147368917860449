import { Select, Spin, Typography } from "antd";
import { useRef } from "react";
import "./styles.css";

const SelectCustom = ({
  title,
  placeholder,
  value,
  options,
  onChange,
  onFocus,
}) => {
  const inputRef = useRef(null);

  const handleDivFocus = () => {
    // Focus vào input khi div được focus
    onFocus();
    inputRef.current && inputRef.current.focus();
  };
  
  return (
    <div
      className="relative flex flex-row justify-between items-center px-3 py-2.5 bg-[#FFFFFF] border border-[#DCB445] rounded-lg custom-select"
      onClick={handleDivFocus}
    >
      <div className="flex-1 flex flex-row w-full justify-start items-center gap-4">
        <div className="flex justify-start">
          <img
            src={"/images/catviet/i_diachi.png"}
            width={24}
            height={24}
            alt=""
          />
        </div>
        <div className="flex-1 w-full flex flex-col">
          <Typography.Text className="text-[15px] leading-[18px] text-[#333333]">
            {title}
          </Typography.Text>
          <Select
            ref={inputRef}
            notFoundContent={
              <span className="flex justify-center py-2">
                <Spin size="small" />
              </span>
            }
            bordered={false}
            suffixIcon={<></>}
            className="w-full z-10"
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            options={options}
          />
        </div>
      </div>
      <div className="flex justify-end opacity-80">
        <img
          src="/images/catviet/arrow_down.png"
          width={14}
          height={8}
          alt=""
        />
      </div>
    </div>
  );
};

export default SelectCustom;
