import RoutesMain from "./routes/routes";
import "./assets/css/global.css";
import "./assets/css/countdown.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function App() {
  const queryClientOption = {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000 * 5,
      },
    },
  };
  const queryClient = new QueryClient(queryClientOption);
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={recaptchaKey}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: "head", // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: "SFUIDisplay",
              },
            }}
          >
            <RoutesMain />
          </ConfigProvider>
        </QueryClientProvider>
      </GoogleReCaptchaProvider>
    </>
  );
}
