import { Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="relative flex flex-col justify-center item-center w-screen max-h-screen h-screen top-0 left-0 z-10 bg-[#FCF4DE]">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
        <Link
          className="py-4 px-5 bg-[#E5454D] fixed bottom-3 rounded-lg transform left-1/2 -translate-x-1/2 whitespace-nowrap text-white shadow"
          to="/"
        >
          Quay lại trang chủ
        </Link>
      </div>
    </>
  );
};
export default NotFound;
