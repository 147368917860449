export const Footer = () => {
  return (
    <div className="hidden md:flex flex-col bg-[#FFF9E9]">
      <div className="flex flex-col w-full h-full">
        <img
          src="/images/catviet/line_pc.png"
          alt=""
          loading="lazy"
          className="object-contain w-full h-full"
        />
      </div>
      <div className="flex flex-col w-full h-full justify-start items-center p-4 gap-4">
        <div className="flex flex-col max-w-screen-lg w-[90%] h-full gap-2">
          <div className="font-abelard text-base font-semibold text-[#AE1F24] mb-2">
            Công ty CP TT & PTPM Cát Việt
          </div>
          <div className="flex flex-row justify-start gap-5">
            <img
              src="/images/catviet/i_location.png"
              alt=""
              loading="lazy"
              className="w-[13px] h-[18px] mt-1"
            />
            <div className="text-base font-medium text-[#743E02]">
              Số 10, Ngõ 2, Phố Quần Ngựa, P. Liễu Giai, Q. Ba Đình, Tp. Hà Nội
            </div>
          </div>
          <div className="flex flex-row justify-start gap-5">
            <img
              src="/images/catviet/i_phone.png"
              alt=""
              loading="lazy"
              className="w-4 h-4 mt-1"
            />
            <div className="text-base font-medium text-[#743E02]">
              024 32321265
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full h-full justify-start items-center bg-[#FFF3A9] p-4">
        <div className="flex flex-col max-w-screen-lg w-[90%] h-full gap-4">
          <img
            src="/images/catviet/bg_bottom_pc.png"
            alt=""
            loading="lazy"
            className="object-contain w-full h-full"
          />
          <div className="text-base text-[#DCB445] text-center">
            © Copyright 2024- PPCLINK
          </div>
        </div>
      </div>
    </div>
  );
};
