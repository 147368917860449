import "./style.css";
import close from "./../../assets/images/close.png";
import { TopBar } from "../../components/TopBar/TopBar";
import { BottomPurchase } from "../../components/BottomPurchase/BottomPurchase";
import { useParams } from "react-router-dom";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { CallApiBackend } from "../../utils/CallApiBackend";
import { DelayToastTime } from "../../constants/TimeConstant";
import {
  ChiTietDonHang,
  GetAddress,
  SuaDonHang,
} from "../../constants/ApiEndPoint";
import { ValidatePhone } from "../../utils/Validations";
import { Button, Image, notification, Typography } from "antd";
import { useImmer } from "use-immer";
import { LoadingCustom } from "../../components/Loading/LoadingCustom";
import { formatPrice } from "../../utils/formatPrice";
import InputCustom from "../../components/InputCustom/InputCustom";
import SelectCustom from "../../components/SelectCustom/SelectCustom";
import MainLayout from "../../layouts/MainLayout";

const { Text } = Typography;

const ItemProductList = ({ item }) => {
  return (
    <>
      {item && (
        <div className="relative flex flex-row justify-between items-center w-full gap-4">
          <div className="flex w-[88px] min-w-[88px] h-auto my-auto justify-center item-center">
            <Image
              src={item.icon || item.product_icon}
              preview={false}
              className={"w-full h-auto object-contain rounded-[10px]"}
            />
          </div>
          <div className="flex flex-col flex-1 justify-between md:justify-center gap-1">
            <div className="flex flex-col md:flex-row w-full justify-between gap-1 items-start md:item-center">
              <div className="flex flex-col w-full gap-1">
                <Text className="text-base font-semibold line-clamp-2">
                  {item.product_name}
                </Text>

                <div className="w-full flex flex-row md:flex-col justify-between">
                  <div className="whitespace-nowrap text-[15px] leading-6 text-black">
                    ({item.classify?.map(({ value }) => value).join(" | ")})
                  </div>
                  <div className="whitespace-nowrap text-right md:text-left text-base font-semibold text-[#743E02]">
                    x {item.qty}
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full justify-end items-center gap-4">
                {item.listed_price !== item.sale_price && (
                  <Text
                    className="whitespace-nowrap text-base text-[#BD9A3B]"
                    delete
                  >
                    {formatPrice(item.listed_price)} đ
                  </Text>
                )}

                <Text className="whitespace-nowrap text-base font-semibold text-black">
                  {formatPrice(item.sale_price)} đ
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export function EditOrderScreen() {
  const { order_id } = useParams();
  const { userData, updateUserInfo } = useContext(UserAuth);
  // const hasHamletProvinces = ["68", "91"];

  const inputNameRef = useRef(null);
  const inputPhoneRef = useRef(null);
  const inputProvinceRef = useRef(null);
  const inputDistrictRef = useRef(null);
  const inputWardRef = useRef(null);
  const inputAddressRef = useRef(null);
  const inputNoteRef = useRef(null);

  const [optionAddress, setOptionAddress] = useImmer({
    province: [],
    district: [],
    ward: [],
    hamlet: [],
  });
  const [location, setLocation] = useImmer({
    province: {},
    district: {},
    ward: {},
    hamlet: {},
    address: "",
    fullname: "",
    phone: "",
  });
  const [orderInfo, setOrderInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // gọi api lấy địa chỉ, type = 1,2,3 - tỉnh - quận - xã
  const CallApiOptionAddress = useCallback(async (input, typeAddress) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
    };
    const result = await CallApiBackend(input, GetAddress, "GET");
    if (result.data.status === 1) {
      const convert = ConvertOptions(result.data.data, typeAddress);
      setOptionAddress((item) => ({
        ...item,
        [keyAddress[typeAddress]]: convert,
      }));
      return convert;
    }
    return [];
  }, []);

  const convertValueDefault = (item) => {
    if (!item) return null;
    return { value: item.code, label: item.name };
  };

  const getDataLocation = async (type) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
      4: "hamlet",
    };
    if (type > 1) {
      if (!location[keyAddress[type - 1]]) {
        return false;
      }
    }

    if (optionAddress[keyAddress[type]]?.length) {
      return;
    }
    const result = await CallApiBackend(
      {
        type: type,
        parent_code: location[keyAddress[type - 1]]?.code,
      },
      GetAddress
    );
    if (result.data.status === 1) {
      const convert = ConvertOptions(result.data.data);
      setOptionAddress((draft) => {
        draft[keyAddress[type]] = convert;
      });
    }
  };

  const handleChangeLocation = async (e, type) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
      4: "hamlet",
    };
    const locationSelect = optionAddress[keyAddress[type]].find(
      (item) => "" + item.value === "" + e
    );

    setOptionAddress((draft) => {
      for (let i = type + 1; i <= 4; i++) {
        draft[keyAddress[i]] = [];
      }
    });
    setLocation((draft) => {
      draft[keyAddress[type]] = {
        code: locationSelect.value,
        name: locationSelect.label,
      };
      for (let i = type + 1; i <= 4; i++) {
        draft[keyAddress[i]] = null;
      }
    });
  };

  // get chi tiet đơn hàng
  useEffect(() => {
    var full_address;
    CallApiBackend({ order_id: order_id }, ChiTietDonHang, "GET")
      .then((result) => {
        setIsLoading(false);
        if (result.data.status === 1) {
          setOrderInfo(result.data.data);
          full_address = result.data.data.full_address;
          for (const key in full_address) {
            if (key === "province" || key === "district" || key === "ward") {
              full_address[key].label = full_address[key].name;
              full_address[key].value = full_address[key].code;
            }
            if (key === "hamlet") {
              let hamlet = {
                label: full_address[key],
                value: full_address[key],
                name: full_address[key],
                code: full_address[key],
              };
              full_address[key] = hamlet;
            }
          }
          setLocation((draft) => {
            draft.province = full_address.province;
            draft.district = full_address.district;
            draft.ward = full_address.ward;
            draft.hamlet = full_address.hamlet;
            draft.address = full_address.address;
            draft.fullname = result.data.data.fullname;
            draft.phone = result.data.data.phone;
            draft.note = result.data.data.note;
          });
          return CallApiOptionAddress({ type: 1 }, 1);
        }
      })
      .then((result1) => {
        if (result1) {
          setOptionAddress((prev) => ({
            ...prev,
            province: result1,
          }));
        }
        return CallApiOptionAddress(
          { type: 2, parent_code: full_address.province.code },
          2
        );
      })
      .then((result2) => {
        if (result2) {
          setOptionAddress((prev) => ({
            ...prev,
            district: result2,
          }));
        }
        return CallApiOptionAddress(
          { type: 3, parent_code: full_address.district.code },
          3
        );
      })
      .then((result3) => {
        if (result3) {
          setOptionAddress((prev) => ({
            ...prev,
            ward: result3,
          }));
        }
      });
  }, [order_id]);

  const ConvertOptions = (list, type) =>
    list.map((item) => ({
      ...item,
      ...{ value: item.code, label: item.name, type: type },
    }));

  const onChangeInput = (element) => {
    // if (element.target.name === "phone") {
    //   const input = element.target;
    //   input.value = input.value.replace(/\D/g, "");
    // }
    console.log("element", element);
    setLocation((item) => ({
      ...item,
      [element.target.name]: element.target.value,
    }));
  };

  const onClickEdit = () => {
    if (
      !location.province ||
      !location.district ||
      !location.ward ||
      !location.address
    ) {
      notification.error({
        message: "Vui lòng điền đầy đủ thông tin giao hàng!",
        placement: "top",
        duration: DelayToastTime,
      });
    } else if (!location.fullname || !location.phone) {
      notification.error({
        message: "Vui lòng điền đầy đủ thông tin người nhận!",
        placement: "top",
        duration: DelayToastTime,
      });
    } else if (ValidatePhone(location.phone).status === false) {
      notification.error({
        message: "Vui lòng điền số điện thoại chính xác!",
        placement: "top",
        duration: DelayToastTime,
      });
    } else {
      const thongTinDonHang = {
        order_id: order_id,

        location: {
          province: {
            code: location.province.code,
            name: location.province.name,
          },
          district: {
            code: location.district.code,
            name: location.district.name,
          },

          ward: {
            code: location.ward.code,
            name: location.ward.name,
          },
          address: location.address,
          hamlet: location.hamlet?.name,
        },
        // cif: userData.cif,
        fullname: location.fullname,
        phone: location.phone,
        note: location.note,
        display_price: orderInfo.payment,
      };
      CallApiBackend(thongTinDonHang, SuaDonHang, "POST").then((result) => {
        if (result.data.status === 1) {
          notification.success({
            message: "Chỉnh sửa đơn hàng thành công",
            placement: "top",
            duration: DelayToastTime,
          });
          if (userData?.is_loggedIn) {
            let userInfoNew = userData;
            userInfoNew.address = {
              ...(userInfoNew?.address || {}),
              metadata: {
                ...(userInfoNew?.address?.metadata || {}),
                location: {
                  province: {
                    code: location.province.code,
                    name: location.province.name,
                  },
                  district: {
                    code: location.district.code,
                    name: location.district.name,
                  },

                  ward: {
                    code: location.ward.code,
                    name: location.ward.name,
                  },
                  address: location.address,
                  hamlet: location.hamlet?.name,
                },
                fullname: location.fullname,
                phone: location.phone,
                note: location.note,
              },
            };
            updateUserInfo({ ...userInfoNew });
          }
          setTimeout(() => {
            window.location.href = "/history/detail/" + order_id;
          }, DelayToastTime);
        }
      });
    }
  };
  const removeValueInput = (name) => {
    console.log("name", name);
    setLocation((item) => ({ ...item, [name]: "" }));
  };

  const handleFocus = (inputRef, block = "center") => {
    setTimeout(function () {
      if (inputRef.current) {
        inputRef.current.scrollIntoView({
          behavior: "smooth",
          block: block,
          inline: "nearest",
        });
      }
    }, 250);
  };

  return (
    <MainLayout>
      <div className="flex flex-col min-h-screen md:min-h-fit bg-[#FCF4DE]">
        <div className="flex md:hidden justify-start w-full">
          <TopBar goHome={false} title={"Chỉnh sửa đơn hàng"} />
        </div>
        {isLoading || !orderInfo ? (
          <div className="relative flex flex-col h-screen bg-[#FCF4DE]">
            <LoadingCustom isLoading={isLoading} isFullScreen={true} />
          </div>
        ) : (
          <>
            <div className="flex flex-col w-full h-full gap-4 px-1.5 py-3.5">
              <div className="flex flex-col bg-[#FFFDF7] border border-[#FFE8AA] rounded-lg px-3 py-4 gap-2">
                <div className="text-lg font-semibold text-[#743E02]">
                  Hương sạch Cát Việt
                </div>
                <div className="relative flex flex-col justify-start gap-4">
                  {orderInfo.order_details.map((orderInfoItem, key) => (
                    <ItemProductList item={orderInfoItem} key={key} />
                  ))}
                </div>
              </div>
              <div className="flex flex-col md:grid md:grid-cols-2 bg-[#FFFDF7] border border-[#FFE8AA] rounded-lg px-3 py-4 gap-2 md:gap-x-6 md:gap-y-4">
                <Text className="col-span-2 text-lg font-semibold text-[#000000]">
                  Thông tin người nhận
                </Text>
                <div ref={inputNameRef}>
                  <InputCustom
                    type="text"
                    placeholder="Nhập tên người nhận"
                    icon="/images/catviet/i_tenkhachhang.png"
                    title="Tên người nhận"
                    name="fullname"
                    id="fullname"
                    value={location?.fullname ?? ""}
                    onChange={(e) => onChangeInput(e)}
                    onFocus={() => {
                      handleFocus(inputNameRef);
                    }}
                    iconClear={location?.fullname ? close : null}
                    onClickClear={() => removeValueInput("fullname")}
                  />
                </div>
                <div ref={inputPhoneRef}>
                  <InputCustom
                    type="number"
                    inputMode="tel"
                    placeholder="Nhập số điện thoại"
                    icon="/images/catviet/i_sdt.png"
                    title="Số điện thoại"
                    id="phone"
                    name="phone"
                    value={location?.phone ?? ""}
                    onChange={onChangeInput}
                    onFocus={() => {
                      handleFocus(inputPhoneRef);
                    }}
                    iconClear={location?.phone ? close : null}
                    onClickClear={() => removeValueInput("phone")}
                  />
                </div>
              </div>
              <div className="flex flex-col md:grid md:grid-cols-2 bg-[#FFFDF7] border border-[#FFE8AA] rounded-lg px-3 py-4 gap-2 md:gap-x-6 md:gap-y-4">
                <Text className="col-span-2 text-lg font-semibold text-[#000000]">
                  Thông tin giao hàng
                </Text>
                <div ref={inputProvinceRef}>
                  <SelectCustom
                    title="Tỉnh/Thành phố"
                    placeholder="Chọn tỉnh/thành phố"
                    options={optionAddress["province"]}
                    value={convertValueDefault(location?.province)}
                    onFocus={() => {
                      getDataLocation(1);
                      handleFocus(inputProvinceRef);
                    }}
                    onChange={(e) => handleChangeLocation(e, 1)}
                  />
                </div>

                <div ref={inputDistrictRef}>
                  <SelectCustom
                    title="Quận/Huyện"
                    placeholder="Chọn quận/huyện"
                    options={optionAddress["district"]}
                    value={convertValueDefault(location?.district)}
                    onFocus={() => {
                      getDataLocation(2);
                      handleFocus(inputDistrictRef);
                    }}
                    onChange={(e) => handleChangeLocation(e, 2)}
                  />
                </div>

                <div ref={inputWardRef}>
                  <SelectCustom
                    title="Phường/Xã"
                    placeholder="Chọn phường/xã"
                    options={optionAddress["ward"]}
                    value={convertValueDefault(location?.ward)}
                    onFocus={() => {
                      getDataLocation(3);
                      handleFocus(inputWardRef);
                    }}
                    onChange={(e) => handleChangeLocation(e, 3)}
                  />
                </div>
                <div ref={inputAddressRef}>
                  <InputCustom
                    icon={"/images/catviet/i_diachi.png"}
                    type="text"
                    placeholder="Nhập địa chỉ"
                    title="Đường, Số nhà, Tòa nhà"
                    name="address"
                    id="address"
                    value={location?.address ?? ""}
                    onChange={(e) => onChangeInput(e)}
                    onFocus={() => {
                      handleFocus(inputAddressRef);
                    }}
                    iconClear={location?.address ? close : null}
                    onClickClear={() => removeValueInput("address")}
                  />
                </div>
                <div className="md:col-span-2" ref={inputNoteRef}>
                  <InputCustom
                    type="text"
                    placeholder="Nhập ghi chú"
                    icon="/images/catviet/i_note.png"
                    title="Ghi chú"
                    name="note"
                    id="note"
                    value={location?.note ?? ""}
                    onChange={(e) => onChangeInput(e)}
                    onFocus={() => {
                      handleFocus(inputNoteRef);
                    }}
                    iconClear={location?.note ? close : null}
                    onClickClear={() => removeValueInput("note")}
                  />
                </div>
              </div>
              <div className="hidden md:flex flex-col sticky bottom-0 z-[100] bg-[#FFFDF7] border border-[#FFE8AA] rounded-lg px-3 py-4 gap-2 md:gap-x-6 md:gap-y-4">
                <div
                  onClick={onClickEdit}
                  className={"flex justify-center items-center"}
                >
                  <Button type="primary" className="button" id="fail-button-1">
                    THAY ĐỔI THÔNG TIN
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex md:hidden justify-start w-full">
              <BottomPurchase paddingX={15} paddingY={20}>
                <div className={"d-flex justify-content-between"}>
                  <div
                    className={"total-money"}
                    style={{ margin: "auto 0" }}
                  ></div>
                  <div
                    onClick={onClickEdit}
                    className={"buy-now"}
                    style={{ width: "100%" }}
                  >
                    <Button
                      type="primary"
                      className="button"
                      id="fail-button-1"
                      style={{ marginTop: "5px", width: "100%" }}
                    >
                      THAY ĐỔI THÔNG TIN
                    </Button>
                  </div>
                </div>
              </BottomPurchase>
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
}
