import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
export default function MainLayout({ children }) {
  return (
    <div id="layout-scroll" className="relative flex flex-col min-h-screen max-h-screen bg-[#FCF4DE] overflow-y-auto">
      <Header />
      <div className="flex-1 max-w-screen-lg w-full md:w-[90%] md:mx-auto md:mt-[132px] md:mb-4 overflow-visible">
        {children}
      </div>
      <Footer />
    </div>
  );
}
