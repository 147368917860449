import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";

const styles = {
  badgeNoti: {
    position: "absolute",
    top: "-10%",
    left: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 4px",
    minWidth: "20px",
    backgroundColor: "#FF0000",
    color: "white",
    fontSize: "12px",
    lineHeight: "12px",
    borderRadius: "10px",
  },
};

const supplier = 4;

export const Header = () => {
  const { userData, cartData } = useContext(UserAuth);
  const navigate = useNavigate();

  return (
    <div className="fixed top-0 z-[99999] hidden md:flex flex-row w-full max-h-[105px] justify-center bg-[#FFD25F] shadow-header">
      <div className="flex flex-row w-[90%] max-w-screen-lg justify-between items-center py-4">
        <div
          className="flex flex-row justify-start items-center w-full h-full gap-4 hover:cursor-pointer"
          onClick={() => navigate("/")}
        >
          <div className="flex w-fit h-full">
            <img
              src="/images/catviet/logo_cat_viet.png"
              alt=""
              loading="lazy"
              className="object-contain max-w-full max-h-full"
            />
          </div>
          <div className="flex justify-start items-center w-full h-full font-abelard text-lg text-[#AD1F25] text-center font-extrabold whitespace-nowrap">
            CAT VIET SHOP
          </div>
        </div>
        <div className="flex flex-row justify-end items-end w-full h-full gap-6">
          <Link
            to={"/gio-hang"}
            style={{ position: "relative" }}
            state={{
              supplier_id: supplier,
            }}
          >
            <div className="flex w-fit h-fit p-2 bg-[#FBBC1E] rounded-full">
              <img
                src="/images/catviet/i_shoping_pc.png"
                alt=""
                className="object-contain w-5 h-5"
              />
            </div>
            {userData?.cart_count?.[supplier] &&
            userData.cart_count[supplier] > 0 ? (
              <span style={styles.badgeNoti}>
                {userData?.cart_count[supplier]}
              </span>
            ) : (
              ""
            )}
            {!userData?.is_loggedIn && cartData?.length > 0 ? (
              <span style={styles.badgeNoti}>{cartData?.length}</span>
            ) : (
              ""
            )}
          </Link>
          {userData?.is_loggedIn && (
            <Link to={"/history"}>
              <div className="flex w-fit h-fit p-[10px] bg-[#FBBC1E] rounded-full">
                <img
                  src="/images/catviet/i_history_pc.png"
                  alt=""
                  className="object-contain w-4 h-4"
                />
              </div>
            </Link>
          )}

          <div className="flex w-fit h-full">
            <img
              src="/images/catviet/logo_tram_huong.png"
              alt=""
              loading="lazy"
              className="object-contain max-w-full max-h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
