import close from "./../../assets/images/close.png";
import { TopBar } from "../../components/TopBar/TopBar";
import { BottomPurchase } from "../../components/BottomPurchase/BottomPurchase";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { CallApiBackend } from "../../utils/CallApiBackend";
import { formatPrice } from "../../utils/formatPrice";
import { DelayToastTime } from "../../constants/TimeConstant";
import {
  GetAddress,
  KiemTraDonHang,
  TaoDonHang,
} from "../../constants/ApiEndPoint";
import { useImmer } from "use-immer";
import { ValidatePhone } from "../../utils/Validations";
import {
  Button,
  Divider,
  Image,
  notification,
  Radio,
  Select,
  Skeleton,
  Space,
  Spin,
  Typography,
} from "antd";
import React from "react";
import { LoadingCustom } from "../../components/Loading/LoadingCustom";
import { getListCardRequest } from "../../service/orderRequest";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import InputCustom from "../../components/InputCustom/InputCustom";
import SelectCustom from "../../components/SelectCustom/SelectCustom";
import MainLayout from "../../layouts/MainLayout";

const { Text } = Typography;

const ItemProductList = ({ item }) => {
  return (
    <>
      {item && (
        <div className="relative flex flex-row justify-between item-center w-full gap-4">
          <div className="flex w-[88px] min-w-[88px] h-auto my-auto justify-center item-center">
            <Image
              src={item.icon || item.product_icon}
              preview={false}
              className={"w-full h-auto object-contain rounded-[10px]"}
            />
          </div>
          <div className="flex flex-col flex-1 justify-between md:justify-center gap-1">
            <div className="flex flex-col md:flex-row w-full justify-between gap-1 items-start md:item-center">
              <div className="flex flex-col w-full gap-1">
                <Text className="text-base font-semibold line-clamp-2">
                  {item.product_name}
                </Text>

                <div className="w-full flex flex-row md:flex-col justify-between">
                  <div className="whitespace-nowrap text-[15px] leading-6 text-black">
                    ({item.classify?.map(({ value }) => value).join(" | ")})
                  </div>
                  <div className="whitespace-nowrap text-right md:text-left text-base font-semibold text-[#743E02]">
                    x {item.qty}
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full justify-end items-center gap-4">
                {item.listed_price !== item.sale_price && (
                  <Text
                    className="whitespace-nowrap text-base text-[#BD9A3B]"
                    delete
                  >
                    {formatPrice(item.listed_price)} đ
                  </Text>
                )}

                <Text className="whitespace-nowrap text-base font-semibold text-black">
                  {formatPrice(item.sale_price)} đ
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ItemProductListPC = ({ item }) => {
  return (
    <>
      {item && (
        <div className="relative grid grid-cols-12 justify-between gap-4">
          <div className="col-span-6 flex flex-row gap-4">
            <div className="flex w-[88px] min-w-[88px] h-auto justify-center item-center">
              <Image
                src={item.icon || item.product_icon}
                preview={false}
                className={"w-full h-auto object-contain rounded-[10px]"}
              />
            </div>
            <div className="flex flex-col flex-1 lg:grid lg:grid-cols-5 justify-center items-center">
              <div className="lg:col-span-2 w-full flex flex-row lg:justify-center lg:items-center">
                <Text className="text-base font-semibold line-clamp-3">
                  {item.product_name}
                </Text>
              </div>
              <div className="lg:col-span-3 w-full flex flex-row lg:justify-center lg:items-center">
                <div className="whitespace-nowrap text-sm text-black">
                  Phân loại:{" "}
                  {item.classify?.map(({ value }) => value).join(" | ")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3 flex flex-row justify-center items-center gap-4">
            {item.listed_price !== item.sale_price && (
              <Text className="whitespace-nowrap text-base font-semibold text-[#BD9A3B]" delete>
                {formatPrice(item.listed_price)} đ
              </Text>
            )}
            <Text className="whitespace-nowrap text-base font-semibold text-black">
              {formatPrice(item.sale_price)} đ
            </Text>
          </div>
          <div className="col-span-1 flex flex-row justify-center items-center">
            <Text className="text-base font-semibold text-black">
              {item.qty}
            </Text>
          </div>

          <div className="col-span-2 flex flex-row justify-center items-center">
            <Text className="whitespace-nowrap text-base font-semibold text-black">
              {formatPrice(item.sale_price * item.qty)} đ
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

export function ScreenPurchaseInformation() {
  const { state } = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();
  const { userData, updateUserInfo, cartData, updateCartInfo } =
    useContext(UserAuth);
  const inputNameRef = useRef(null);
  const inputPhoneRef = useRef(null);
  const inputProvinceRef = useRef(null);
  const inputDistrictRef = useRef(null);
  const inputWardRef = useRef(null);
  const inputAddressRef = useRef(null);
  const inputNoteRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [loadingListCard, setLoadingListCard] = useState(false);
  const [formSubmitOrder, setFormSubmitOrder] = useImmer({
    classifies: {},
    location: {},
    fullname: "",
    phone: "",
    note: "",
    is_cart: false,
    display_price: 0,
    payment_type: 0,
    polixy_check: false,
    card_id: "",
  });
  const [listCard, setListCard] = useState(null);
  const [paymentType, setPaymentType] = useState([]);
  const [optionAddress, setOptionAddress] = useImmer({
    province: [],
    district: [],
    ward: [],
    hamlet: [],
  });
  const [orderInfo, setOrderInfo] = useState(null);
  // const hasHamletProvinces = ["68", "91"];

  const [loadingButtonBuyNow, setLoadingButtonBuyNow] = useState(false);

  const listLocationRender = [
    {
      name: "Tỉnh/Thành phố",
      type: 1,
      key: "province",
      ref: inputProvinceRef,
      placeholder: "Chọn tỉnh/thành phố",
    },
    {
      name: "Quận/Huyện",
      type: 2,
      key: "district",
      ref: inputDistrictRef,
      placeholder: "Chọn quận/huyện",
    },
    {
      name: "Phường/Xã",
      type: 3,
      key: "ward",
      ref: inputWardRef,
      placeholder: "Chọn phường/xã",
    },
    {
      name: "Tên thôn/ấp/xóm/tổ",
      type: 4,
      key: "hamlet",
      check: "isHamlet",
      placeholder: "",
    },
  ];

  // lấy thông tin đơn hàng
  useEffect(() => {
    if (state && !orderInfo) {
      let { classify } = state;

      const classifies = classify.reduce(
        (obj, item) => ({ ...obj, [item.classify_id]: item.qty }),
        {}
      );

      // Kiểm tra đơn hàng
      CallApiBackend(
        {
          classifies: classifies,
          location: {
            province: userData?.address?.province,
            district: userData?.address?.district,
            ward: userData?.address?.ward,
          },
          is_cart: state.isCart,
        },
        KiemTraDonHang,
        "POST"
      ).then(({ data }) => {
        if (data.status === 1) {
          setOrderInfo(data.data);
          //set default order submit
          setFormSubmitOrder((draft) => {
            draft.note =
              userData?.address?.metadata?.note ||
              userData?.address?.note ||
              "";
            draft.location =
              userData?.address?.metadata?.shipment_mode ===
                userData?.current_ship_mode && userData?.current_ship_mode
                ? userData?.address?.metadata || {}
                : {};
            draft.classifies = classifies;
            draft.fullname =
              userData?.address?.metadata?.fullname || userData?.fullname;
            draft.phone = userData?.address?.metadata?.phone || userData?.phone;
            draft.is_cart = state.isCart;
            draft.display_price =
              data.data.sale_price +
              data.data.transport_fee -
              data.data.transport_fee_discount;
            draft.payment_type = data.data.payment_type.find(
              (item) => item.active && !item.card_list
            )?.value;
          });
          //
          //setup các loại thanh toán
          const listPayment = data.data.payment_type
            .filter((item) => item.active)
            .reduce((array, item) => {
              if (item.group === 0 || !item.group) {
                array.push(item);
              } else {
                const indexArray = array.findIndex(
                  (i) => i.key_group === item.group
                );
                if (indexArray >= 0) {
                  array[indexArray].groups.push(item);
                } else {
                  array.push({
                    title: item.title,
                    description: item.description,
                    groups: [item],
                    key_group: item.group,
                  });
                }
              }
              return array;
            }, []);
          setPaymentType(listPayment);
        } else {
          //noti order fail
          notification.error({
            message: data.message || "Có lỗi xảy ra. Vui lòng thử lại sau!",
            placement: "top",
            duration: DelayToastTime,
          });
          navigate(-1);
        }
        setLoading(false);
      });
    }
  }, [state, userData, orderInfo, setFormSubmitOrder]);

  //Lấy dữ liệu location
  const ConvertOptions = (list) =>
    list.map((item) => ({ value: item.code, label: item.name }));

  const getDataLocation = async (type) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
      4: "hamlet",
    };
    if (type > 1) {
      if (!formSubmitOrder.location[keyAddress[type - 1]]) {
        return false;
      }
    }
    if (optionAddress[keyAddress[type]]?.length) {
      return;
    }
    const result = await CallApiBackend(
      {
        type: type,
        parent_code: formSubmitOrder.location[keyAddress[type - 1]]?.code,
      },
      GetAddress
    );
    if (result.data.status === 1) {
      const convert = ConvertOptions(result.data.data);
      setOptionAddress((draft) => {
        draft[keyAddress[type]] = convert;
      });
    }
  };
  const handleChangeLocation = async (e, type) => {
    const keyAddress = {
      1: "province",
      2: "district",
      3: "ward",
      4: "hamlet",
    };
    const locationSelect = optionAddress[keyAddress[type]].find(
      (item) => "" + item.value === "" + e
    );
    setOptionAddress((draft) => {
      for (let i = type + 1; i <= 4; i++) {
        draft[keyAddress[i]] = [];
      }
    });
    setFormSubmitOrder((draft) => {
      draft.location[keyAddress[type]] = {
        code: locationSelect.value,
        name: locationSelect.label,
      };
      for (let i = type + 1; i <= 4; i++) {
        draft.location[keyAddress[i]] = null;
      }
    });
    // if (type === 3 && !orderInfo.isHamlet) {
    //   const optionLv4 = await getLv4Address({
    //     location: {
    //       province: formSubmitOrder.location.province.name,
    //       district: formSubmitOrder.location.district.name,
    //       ward: locationSelect.label,
    //     },
    //   });
    //   if (Array.isArray(optionLv4) && optionLv4.length) {
    //     setOptionAddress((draft) => {
    //       draft.hamlet = optionLv4.map((item) => ({
    //         value: item,
    //         label: item,
    //       }));
    //     });
    //   }
    // }
  };

  const convertValueDefault = (item) => {
    if (!item) return null;
    return { value: item.code, label: item.name };
  };

  // ấn nút thanh toán
  const onClickPayment = async () => {
    setLoadingButtonBuyNow(true);
    if (
      !formSubmitOrder.location.province ||
      !formSubmitOrder.location.district ||
      !formSubmitOrder.location.ward ||
      !formSubmitOrder.location.address
    ) {
      notification.error({
        message: "Vui lòng điền đầy đủ thông tin giao hàng!",
        placement: "top",
        duration: DelayToastTime,
      });
      setLoadingButtonBuyNow(false);
    } else if (!formSubmitOrder.fullname || !formSubmitOrder.phone) {
      notification.error({
        message: "Vui lòng điền đầy đủ thông tin người nhận!",
        placement: "top",
        duration: DelayToastTime,
      });
      setLoadingButtonBuyNow(false);
    } else if (ValidatePhone(formSubmitOrder.phone).status === false) {
      notification.error({
        message: "Vui lòng điền số điện thoại chính xác!",
        placement: "top",
        duration: DelayToastTime,
      });
      setLoadingButtonBuyNow(false);
    } else {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        setLoadingButtonBuyNow(false);
        return;
      }
      let recaptchaValue = await executeRecaptcha("create_order");
      CallApiBackend(
        { ...formSubmitOrder, recaptcha: recaptchaValue },
        TaoDonHang,
        "POST"
      ).then((result) => {
        if (result.data.status === 1) {
          if (userData?.is_loggedIn) {
            let userInfoNew = userData;
            if (state.isCart === true) {
              let { cart_count } = userData;
              if (cart_count) {
                state.classify.forEach((item) => {
                  if (cart_count[item.supplier_id])
                    cart_count[item.supplier_id] -= 1;
                });
                userInfoNew.cart_count = cart_count;
              }
            }
            userInfoNew.address = {
              ...(userInfoNew?.address || {}),
              metadata: {
                ...formSubmitOrder.location,
                fullname: formSubmitOrder.fullname,
                phone: formSubmitOrder.phone,
                note: formSubmitOrder.note,
                shipment_mode: userData?.current_ship_mode,
              },
            };
            updateUserInfo({ ...userInfoNew });
          }

          if (!userData?.is_loggedIn && cartData) {
            if (state.isCart) {
              const cartDataTemp = cartData?.filter(
                (cartDataItem) =>
                  !Object.keys(formSubmitOrder?.classifies)
                    ?.map(Number)
                    ?.includes(cartDataItem?.classify_id)
              );
              updateCartInfo(cartDataTemp);
            }
          }

          if (result?.data?.data?.payment_url) {
            window.location.href = result?.data?.data?.payment_url;
          } else {
            navigate(`/order/result?orderId=${result?.data?.data?.order_id}`);
          }
        } else {
          notification.error({
            message: result.data.message || "Có lỗi xảy ra, vui lòng thử lại!",
            placement: "top",
            duration: DelayToastTime,
          });
        }
        setLoadingButtonBuyNow(false);
      });
    }
  };

  //chọn phương thức thanh toán
  const onChangePaymentType = async (e) => {
    const payment_current = orderInfo.payment_type.find(
      (item) => item.value === e.target.value
    );
    if (payment_current.card_list) {
      //lấy danh sách thẻ
      if (!listCard) {
        setLoadingListCard(true);
        const { data } = await getListCardRequest({
          payment_type: payment_current.value,
        }).then((res) => {
          setLoadingListCard(false);
          return res;
        });
        if (data.status === 1 && data.data?.length > 0) {
          setListCard(
            data.data.map((item) => ({
              id: item.card_id,
              cardNumber: item.card_number,
            }))
          );
        } else {
          setListCard([]);
          notification.error({
            message:
              "Quý khách chưa có thẻ. Vui lòng đăng ký để sử dụng ưu đãi này hoặc chọn phương thức thanh toán khác!",
            placement: "top",
            duration: DelayToastTime,
          });
          return;
        }
      } else if (listCard.length === 0) {
        notification.error({
          message:
            "Quý khách chưa có thẻ. Vui lòng đăng ký để sử dụng ưu đãi này hoặc chọn phương thức thanh toán khác!",
          placement: "top",
          duration: DelayToastTime,
        });
        return;
      }
    }
    setFormSubmitOrder((draft) => {
      draft.payment_type = e.target.value;
      draft.display_price = payment_current?.payAmount;
    });
  };
  const changeCardId = (e) => {
    setFormSubmitOrder((draft) => {
      draft.card_id = e.target.value;
    });
  };

  const handleFocus = (inputRef, block = "center") => {
    setTimeout(function () {
      if (inputRef.current) {
        inputRef.current.scrollIntoView({
          behavior: "smooth",
          block: block,
          inline: "nearest",
        });
      }
    }, 250);
  };

  return (
    <MainLayout>
      <div className="flex flex-col min-h-screen md:min-h-fit bg-[#FCF4DE]">
        <div className="flex md:hidden justify-start w-full">
          <TopBar goHome={false} title={"Thanh toán"} />
        </div>
        <LoadingCustom isLoading={loadingListCard} isFullScreen={false} />
        {!loading && orderInfo ? (
          <>
            <div className="flex flex-col w-full h-full gap-4 px-1.5 py-3.5">
              <div className="flex md:hidden flex-col bg-[#FFFDF7] border border-[#FFE8AA] rounded-lg px-3 py-4 gap-2">
                <div className="text-lg font-semibold text-[#743E02]">
                  Hương sạch Cát Việt
                </div>
                <div className="relative flex flex-col justify-start gap-4">
                  {orderInfo.data?.[0]?.classifies.map((orderInfoItem, key) => (
                    <ItemProductList
                      item={{
                        ...orderInfoItem,
                        qty: formSubmitOrder.classifies[
                          orderInfoItem.classify_id
                        ],
                      }}
                      key={key}
                    />
                  ))}
                </div>
                <Divider className="md:hidden my-0.5 bg-[#EBDEBE]" />

                <div className="flex md:hidden flex-col gap-2">
                  <div className="flex flex-row justify-between gap-2">
                    <div className="text-base text-[#333333]">Tiền hàng</div>
                    <div className="flex-1 flex justify-end text-right text-base font-semibold">
                      {formatPrice(orderInfo.data?.[0]?.sale_price)} đ
                    </div>
                  </div>
                  <div className="flex flex-row justify-between gap-2">
                    <div className="text-base text-[#333333]">
                      Phí giao hàng
                    </div>
                    <div className="flex-1 flex justify-end text-right text-base font-semibold">
                      {formatPrice(orderInfo.data?.[0]?.transport_fee)} đ
                    </div>
                  </div>
                  {orderInfo.data?.[0]?.transport_fee_discount &&
                  orderInfo.data?.[0]?.transport_fee_discount > 0 ? (
                    <div className="flex flex-row justify-between gap-2">
                      <div className="text-base text-[#333333]">
                        Ưu đãi phí giao hàng
                      </div>
                      <div className="flex-1 flex justify-end text-right text-base font-semibold">
                        -
                        {formatPrice(
                          orderInfo.data?.[0]?.transport_fee_discount
                        )}{" "}
                        đ
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex flex-row justify-between gap-2">
                    <div className="text-base text-[#333333]">
                      Tổng tiền thanh toán
                    </div>
                    <div className="flex-1 flex justify-end text-right text-base font-semibold text-[#E72B3D]">
                      {formatPrice(
                        orderInfo.data?.[0]?.sale_price +
                          orderInfo.data?.[0]?.transport_fee -
                          orderInfo.data?.[0]?.transport_fee_discount -
                          orderInfo.payment_type.find(
                            (e) => e.value === formSubmitOrder.payment_type
                          )?.pay_discount || 0
                      )}{" "}
                      đ
                    </div>
                  </div>
                  {orderInfo.data?.[0]?.payment_needed_to_freeship &&
                  orderInfo.data?.[0]?.payment_needed_to_freeship > 0 ? (
                    <div className="flex flex-row justify-end gap-1">
                      <div className="w-fit">
                        <img
                          src="/images/catviet/i_shipper.png"
                          alt=""
                          className="w-6 h-auto object-contain"
                        />
                      </div>
                      <Text className="w-fit text-right text-base font-semibold text-[#E72B3D]">
                        Mua thêm{" "}
                        {formatPrice(
                          orderInfo.data?.[0]?.payment_needed_to_freeship
                        )}{" "}
                        đ để miễn phí giao hàng
                      </Text>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="flex flex-col md:grid md:grid-cols-2 bg-[#FFFDF7] border border-[#FFE8AA] rounded-lg px-3 py-4 gap-2 md:gap-x-6 md:gap-y-4">
                <Typography.Text className="col-span-2 text-lg font-semibold text-[#000000]">
                  Thông tin người nhận
                </Typography.Text>
                <div ref={inputNameRef}>
                  <InputCustom
                    type="text"
                    placeholder="Nhập tên người nhận"
                    icon="/images/catviet/i_tenkhachhang.png"
                    title="Tên người nhận"
                    value={formSubmitOrder.fullname}
                    onChange={(e) =>
                      setFormSubmitOrder((draft) => {
                        draft.fullname = e.target.value;
                      })
                    }
                    onFocus={() => handleFocus(inputNameRef)}
                    iconClear={formSubmitOrder.fullname ? close : null}
                    onClickClear={() =>
                      setFormSubmitOrder((draft) => {
                        draft.fullname = "";
                      })
                    }
                  />
                </div>
                <div ref={inputPhoneRef}>
                  <InputCustom
                    type="number"
                    inputMode="tel"
                    placeholder="Nhập số điện thoại"
                    icon="/images/catviet/i_sdt.png"
                    title="Số điện thoại"
                    value={formSubmitOrder.phone}
                    onChange={(e) =>
                      setFormSubmitOrder((draft) => {
                        draft.phone = e.target.value || "";
                      })
                    }
                    onFocus={() => handleFocus(inputPhoneRef)}
                    iconClear={formSubmitOrder.phone ? close : null}
                    onClickClear={() =>
                      setFormSubmitOrder((draft) => {
                        draft.phone = "";
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col md:grid md:grid-cols-2 bg-[#FFFDF7] border border-[#FFE8AA] rounded-lg px-3 py-4 gap-2 md:gap-x-6 md:gap-y-4">
                <Typography.Text className="col-span-2 text-lg font-semibold text-[#000000]">
                  Thông tin giao hàng
                </Typography.Text>
                {listLocationRender.map(
                  (item, key) =>
                    (!item.check || orderInfo[item.check]) && (
                      <div ref={item?.ref} key={key}>
                        <SelectCustom
                          title={item?.name}
                          placeholder={item?.placeholder}
                          value={convertValueDefault(
                            formSubmitOrder.location[item.key]
                          )}
                          options={optionAddress[item.key]}
                          onFocus={() => {
                            getDataLocation(item.type);
                            handleFocus(item?.ref);
                          }}
                          onChange={(e) => handleChangeLocation(e, item.type)}
                        />
                      </div>
                    )
                )}
                <div ref={inputAddressRef}>
                  <InputCustom
                    type="text"
                    placeholder="Nhập địa chỉ"
                    icon="/images/catviet/i_diachi.png"
                    title="Đường, Số nhà, Tòa nhà"
                    value={formSubmitOrder.location?.address || ""}
                    onChange={(e) =>
                      setFormSubmitOrder((draft) => {
                        draft.location = {
                          ...(draft.location || {}),
                          address: e.target.value,
                        };
                      })
                    }
                    onFocus={() => handleFocus(inputAddressRef)}
                    iconClear={
                      formSubmitOrder.location?.address ? close : null
                    }
                    onClickClear={() =>
                      setFormSubmitOrder((draft) => {
                        draft.location = {
                          ...(draft.location || {}),
                          address: "",
                        };
                      })
                    }
                  />
                </div>
                <div className="col-span-2" ref={inputNoteRef}>
                  <InputCustom
                    ref={inputNoteRef}
                    type="text"
                    placeholder="Nhập ghi chú"
                    icon="/images/catviet/i_note.png"
                    title="Ghi chú"
                    value={formSubmitOrder.note}
                    iconClear={formSubmitOrder.note ? close : null}
                    onClickClear={() =>
                      setFormSubmitOrder((draft) => {
                        draft.note = "";
                      })
                    }
                    onChange={(e) =>
                      setFormSubmitOrder((draft) => {
                        draft.note = e.target.value;
                      })
                    }
                    onFocus={() => handleFocus(inputAddressRef)}
                  />
                </div>
              </div>
              <div className="hidden md:flex flex-col bg-[#FFFDF7] border border-[#FFE8AA] rounded-lg px-3 py-4 gap-4">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-6 flex justify-start items-center">
                    <Text className="whitespace-nowrap text-base text-[#811517] font-semibold">
                      Sản phẩm
                    </Text>
                  </div>
                  <div className="col-span-3 flex justify-center items-center">
                    <Text className="whitespace-nowrap text-base text-[#811517] font-semibold">
                      Đơn giá
                    </Text>
                  </div>
                  <div className="col-span-1 flex justify-center items-center">
                    <Text className="whitespace-nowrap text-base text-[#811517] font-semibold">
                      Số lượng
                    </Text>
                  </div>
                  <div className="col-span-2 flex justify-center items-center">
                    <Text className="whitespace-nowrap text-base text-[#811517] font-semibold">
                      Số tiền
                    </Text>
                  </div>
                </div>
                <div className="relative flex flex-col justify-start gap-4">
                  {orderInfo.data?.[0]?.classifies.map((orderInfoItem, key) => (
                    <>
                      <ItemProductListPC
                        item={{
                          ...orderInfoItem,
                          qty: formSubmitOrder.classifies[
                            orderInfoItem.classify_id
                          ],
                        }}
                        key={key}
                      />
                      {orderInfo.data?.[0]?.classifies.length - 1 > 0 &&
                        orderInfo.data?.[0]?.classifies.length - 1 !== key && (
                          <Divider className="my-0.5 bg-[#EBDEBE]" />
                        )}
                    </>
                  ))}
                </div>
              </div>
              <div className="flex flex-col md:grid md:grid-cols-2 bg-[#FFFDF7] border border-[#FFE8AA] rounded-lg px-3 py-4 gap-2 md:gap-y-4 md:gap-x-8 xl:gap-x-12 custom-select1">
                <Typography.Text className="col-span-2 text-lg font-semibold text-[#000000]">
                  Phương thức thanh toán
                </Typography.Text>
                <div className="flex justify-start">
                  <Radio.Group
                    className="custom-radio-group w-full"
                    onChange={onChangePaymentType}
                    value={formSubmitOrder.payment_type}
                  >
                    <Space direction="vertical">
                      {paymentType.map((item, key) => (
                        <div
                          className="px-3 py-2.5 border border-[#FFE8AA] rounded"
                          key={key}
                        >
                          {item.groups ? (
                            <>
                              <div className="w-10/12">
                                <Text className="block text-[#743E02] uppercase font-semibold">
                                  {item?.title}
                                </Text>
                                <Text className="text-11 block font-light">
                                  {item?.description}
                                </Text>
                              </div>
                              {item.groups && (
                                <Space
                                  direction="vertical"
                                  className="py-1"
                                  size={10}
                                  split={
                                    <span className="block h-1 border-b w-full border-gray-200" />
                                  }
                                >
                                  {item.groups.map((_i, _k) => (
                                    <div key={_k}>
                                      <Radio value={_i.value} className="py-2">
                                        <span
                                          className="block"
                                          dangerouslySetInnerHTML={{
                                            __html: _i.metadata?.title,
                                          }}
                                        />
                                        <Text className="text-11 font-light">
                                          {_i.metadata?.description}
                                        </Text>
                                      </Radio>
                                      {_i.card_list &&
                                        formSubmitOrder.payment_type ===
                                          _i.value && (
                                          <>
                                            <LoadingCustom
                                              isLoading={false}
                                              isFullScreen={false}
                                            />
                                            <Radio.Group
                                              name="card"
                                              onChange={changeCardId}
                                              value={formSubmitOrder.card_id}
                                              className="custom-radio-group w-full"
                                            >
                                              <Space
                                                direction="vertical border-t border-gray-200 pt-3"
                                                size={10}
                                                split={
                                                  <span className="block h-1 border-b w-full border-gray-200" />
                                                }
                                              >
                                                {listCard?.length > 0 &&
                                                  listCard.map((__i, __k) => (
                                                    <Radio
                                                      value={__i.id}
                                                      className="pl-5"
                                                      key={__k}
                                                    >
                                                      <Text>
                                                        {__i.cardNumber}
                                                      </Text>
                                                    </Radio>
                                                  ))}
                                              </Space>
                                            </Radio.Group>
                                          </>
                                        )}
                                    </div>
                                  ))}
                                </Space>
                              )}
                            </>
                          ) : (
                            <Radio value={item.value}>
                              <div className="flex flex-row justify-start items-center gap-5">
                                <img
                                  src={`/images/catviet/${item?.icon}.png`}
                                  alt=""
                                  className="w-7 h-auto object-contain"
                                />
                                <Text className="block text-[#743E02] uppercase font-semibold">
                                  {item?.title}
                                </Text>
                              </div>
                            </Radio>
                          )}
                        </div>
                      ))}
                    </Space>
                  </Radio.Group>
                </div>
                <div className="hidden md:flex flex-col justify-end pt-3 gap-2">
                  <div className="flex flex-row justify-between gap-2">
                    <div className="text-base text-[#333333]">Tiền hàng</div>
                    <div className="flex-1 flex justify-end text-right text-base font-semibold">
                      {formatPrice(orderInfo.data?.[0]?.sale_price)} đ
                    </div>
                  </div>
                  <div className="flex flex-row justify-between gap-2">
                    <div className="text-base text-[#333333]">
                      Phí giao hàng
                    </div>
                    <div className="flex-1 flex justify-end text-right text-base font-semibold">
                      {formatPrice(orderInfo.data?.[0]?.transport_fee)} đ
                    </div>
                  </div>
                  {orderInfo.data?.[0]?.transport_fee_discount &&
                  orderInfo.data?.[0]?.transport_fee_discount > 0 ? (
                    <div className="flex flex-row justify-between gap-2">
                      <div className="text-base text-[#333333]">
                        Ưu đãi phí giao hàng
                      </div>
                      <div className="flex-1 flex justify-end text-right text-base font-semibold">
                        -
                        {formatPrice(
                          orderInfo.data?.[0]?.transport_fee_discount
                        )}{" "}
                        đ
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="flex flex-row justify-between gap-2">
                    <div className="text-base text-[#333333]">
                      Tổng tiền thanh toán
                    </div>
                    <div className="flex-1 flex justify-end text-right text-base font-semibold text-[#E72B3D]">
                      {formatPrice(
                        orderInfo.data?.[0]?.sale_price +
                          orderInfo.data?.[0]?.transport_fee -
                          orderInfo.data?.[0]?.transport_fee_discount -
                          orderInfo.payment_type.find(
                            (e) => e.value === formSubmitOrder.payment_type
                          )?.pay_discount || 0
                      )}{" "}
                      đ
                    </div>
                  </div>
                  {orderInfo.data?.[0]?.payment_needed_to_freeship &&
                  orderInfo.data?.[0]?.payment_needed_to_freeship > 0 ? (
                    <div className="flex justify-end gap-2">
                      <div className="flex justify-start items-start">
                        <img
                          src="/images/catviet/i_shipper.png"
                          alt=""
                          className="w-6 h-auto object-contain"
                        />
                      </div>
                      <Text className="w-fit text-right text-base font-semibold text-[#E72B3D]">
                        Mua thêm{" "}
                        {formatPrice(
                          orderInfo.data?.[0]?.payment_needed_to_freeship
                        )}{" "}
                        đ để miễn phí giao hàng
                      </Text>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="hidden md:flex flex-row sticky bottom-0 z-[100] justify-end items-center bg-[#FFFDF7] border border-[#FFE8AA] rounded-lg px-3 py-4 gap-2">
                <div className="text-md text-black font-medium text-center">
                  Tổng tiền thanh toán:
                </div>
                <div className={"total-money"} style={{ margin: "auto 0" }}>
                  <p className="text-xl text-[#E5454D] lowercase">
                    {formatPrice(
                      orderInfo.payment_type.find(
                        (e) => e.value === formSubmitOrder.payment_type
                      )?.payAmount ?? 0
                    )}
                    {" đ"}
                  </p>
                </div>
                <div className={"buy-now ml-4"}>
                  <Button
                    loading={loadingButtonBuyNow}
                    onClick={onClickPayment}
                    type="primary"
                    className="w-full button py-2 h-12"
                  >
                    Đặt hàng
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex md:hidden justify-start w-full">
              <BottomPurchase paddingX={15} paddingY={20}>
                <div className={"d-flex justify-content-between"}>
                  <div className={"total-money"} style={{ margin: "auto 0" }}>
                    <p className="text-xl text-[#E5454D] lowercase">
                      {formatPrice(
                        orderInfo.payment_type.find(
                          (e) => e.value === formSubmitOrder.payment_type
                        )?.payAmount ?? 0
                      )}
                      {" đ"}
                    </p>
                  </div>
                  <div className={"buy-now"}>
                    <Button
                      loading={loadingButtonBuyNow}
                      onClick={onClickPayment}
                      type="primary"
                      className="w-full button py-2 h-12"
                    >
                      Đặt hàng
                    </Button>
                  </div>
                </div>
              </BottomPurchase>
            </div>
          </>
        ) : (
          <Skeleton paragraph={{ rows: 10 }} active className="p-3" />
        )}
      </div>
    </MainLayout>
  );
}
