import { Input, Typography } from "antd";
import "./styles.css";
import { useRef } from "react";

const InputCustom = ({
  type = "text",
  inputMode = "text",
  icon = null,
  title,
  placeholder,
  name = "",
  id = "",
  value,
  onChange,
  onFocus,
  iconClear = null,
  onClickClear = () => {},
}) => {
  const inputRef = useRef(null);

  const handleDivFocus = () => {
    // Focus vào input khi div được focus
    onFocus();
    inputRef.current && inputRef.current.focus();
  };

  const handleBeforeInputPhoneNumber = (e) => {
    if (e.type === "beforeinput") {
      // Kiểm tra ký tự nhập vào bằng tay
      const isNumberKey = /^[0-9]$/.test(e.data);
      if (!isNumberKey) {
        e.preventDefault(); // Ngăn ký tự không hợp lệ
      }
    } else if (e.type === "paste") {
      // Xử lý khi người dùng dán giá trị
      const pasteData = (e.clipboardData || window.clipboardData).getData(
        "text"
      );

      onChange({
        target: { name: name, value: pasteData.replace(/[^0-9]/g, "") },
      }); // Gọi hàm onChange với giá trị mới
    }
  };

  return (
    <div
      className="relative flex flex-row justify-between items-center px-3 py-2.5 bg-[#FFFFFF] border border-[#DCB445] rounded-lg custom-input"
      onClick={handleDivFocus}
    >
      <div className="flex-1 flex flex-row w-full justify-start items-center gap-4">
        {icon ? (
          <div className="flex justify-start">
            <img src={icon} width={24} height={24} alt="" />
          </div>
        ) : (
          <></>
        )}

        <div className="flex-1 w-full flex flex-col">
          <Typography.Text className="text-[15px] leading-[18px] text-[#333333]">
            {title}
          </Typography.Text>
          <Input
            ref={inputRef}
            type={type}
            inputMode={inputMode}
            placeholder={placeholder}
            bordered={false}
            onBeforeInput={(e) => {
              if (type === "number") {
                handleBeforeInputPhoneNumber(e);
              }
            }}
            onPaste={(e) => {
              if (type === "number") {
                handleBeforeInputPhoneNumber(e);
              }
            }}
            onChange={onChange}
            rootClassName="px-0 z-10"
            name={name}
            id={id}
            value={value}
          />
        </div>
      </div>
      {iconClear ? (
        <div
          onClick={onClickClear}
          className="flex justify-center opacity-80 cursor-pointer"
        >
          <img src={iconClear} width={18} height={18} alt="" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InputCustom;
