import './style.css';
import {useEffect, useRef, useState} from "react";


export const BottomPurchase = ({
  children,
  paddingX = 40,
  paddingY = 0,
  enablePull = false,
  radiusBorder = 0,
  doSomeThing = null
}) => {
  const ref = useRef(null);
  const H_MAX = 800;
  const H_MIN = 60
  const [maxheight, setMaxHeight] = useState(H_MAX);

  useEffect(() => {
    if (ref.current) {
      setMaxHeight(ref.current.offsetHeight)
    }
  }, [])


  useEffect(() => {
    if (enablePull) {
      if (maxheight <= H_MIN) {
        doSomeThing(true);
      } else {
        doSomeThing(false);
      }
    }
  }, [maxheight, doSomeThing, enablePull]);

  return (
    <footer style={{minHeight: maxheight}}>
      <div
        className={'bottom-purchase'}
        ref={ref}
        style={{
          borderTopLeftRadius: radiusBorder,
          borderTopRightRadius: radiusBorder,
          padding: `${paddingY}px ${paddingX}px`,
          width: '100%',
          // maxWidth: `calc(500px - ${paddingX * 2}px`,
        }}>
        <div style={{width: '100%'}} className="relative">
          {children}
        </div>
      </div>
    </footer>
  )
}